import { message } from 'antd';
import _axios, { AxiosRequestConfig } from 'axios';
import { UserInfo } from 'typings/user';
// import qs from "qs";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface RequestResult<T = any> {
  code:
    | '000000' // 正常
    | '000001' // 登录异常
    | '000002' // 路由异常
    | '000003' // 数据库异常
    | '000004' // 参数异常
    | '000005' // 阻止有效数据删除
    | '000006' // 权限不足
    | '000007' // 文件上传异常
    | '000008' // 非法操作
    | '000009' // 数据过期
    | '000010' // 验证码错误
    | '000011' // 短信发送失败
    | '000012' // 文件删除异常
    | '000013' // 逻辑阻断
    | '000014' // 未登录
    | '000015' // 签名异常
    | '000016' // 第三方错误
    | '000017' // 异常抛出
    | '000018' // 程序中途终止
    | '000019' // 文件不存在
    | '000020'; // 用户未实名
  data: T;
  message: string;
}

declare module 'axios' {
  interface AxiosInstance {
    (config: AxiosRequestConfig): Promise<any>;
  }
}
const axios = _axios.create({
  timeout: 0, // Timeout
});

// axios.defaults.baseURL = none;

axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

/**
 * 请求之前触发的事件
 */
// let pathc: AxiosRequestConfig<any>;
axios.interceptors.request.use(
  (config) => {
    // pathc = config;
    const { headers } = config;
    const userInfoJson = window.sessionStorage.getItem('userInfo');
    if (userInfoJson) {
      const userInfo: UserInfo = JSON.parse(userInfoJson);
      const { token, extensionId } = userInfo;
      if (headers != null && !headers.Token) {
        headers.Token = token;
        headers.ExtensionId = extensionId;
      }
    }
    return config;
  },
  (error) => {
    // window.electron.log("发送请求错误!", `,path:${pathc.url},${error}`);
    return Promise.reject(error);
  },
);
/**
 * 数据返回后触发的事件
 */
axios.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code === '000017' || res.code === '000006') {
      window.location.href = '/login';
      message.error('账号被登出');
    }
    if (res.code !== '000000') {
      // window.electron.log(
      // 	`请求接收错误!,path:${pathc.url}code:${res.code},message:${res.message}`
      // );
    }
    return Promise.resolve(res);
  },
  (error) => {
    // window.electron.log(`请求接收错误!,path:${pathc.url},error:${error}`);
    return Promise.reject(error);
  },
);
function ajax<T>(obj: AxiosRequestConfig) {
  return new Promise<RequestResult<T>>((resolve, reject) => {
    const baseURL =
      !window.isDevelopment && process.env.REACT_APP_ENV === 'production'
        ? 'https://huihuvip.youxin2017.com'
        : 'http://huihu.zjfantian.cn:8890';
    obj.url = baseURL + obj.url;
    axios(obj)
      .then((result: RequestResult) => {
        /** 统一处理获取数据列表items字段若返回null 处理成空数组 */
        if (
          result.code === '000000' &&
          result.data &&
          result.data.items === null
        ) {
          result.data.items = [];
        }
        return resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export default ajax;
